import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../script/firebase";
import { Link, useNavigate } from "react-router-dom";
import Signup from "./signup";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        navigate("/profil");
        console.log(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  };

  return (
    <>
      <div className="box">
        <div className="card-center">
          <form>
            <div>
              <label className="subtitle" htmlFor="email-address">
                E-Post
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                className="input"
                required
                placeholder="Email address"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div>
              <label className="subtitle" htmlFor="password">
                Lösenord
              </label>
              <input
                id="password"
                name="password"
                type="password"
                className="input"
                required
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="button-center">
              <button className="button is-primary" onClick={onLogin}>
                Logga in
              </button>
            </div>
            <div className="lankar">
              <Link to="/signup">Inget konto, registrera ett !</Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
