import React from "react";

function CookiePolicy() {
  return (
    <div className="box">
      <h2 className="title">Cookie-policy för DevBlog</h2>
      <p>Senast uppdaterad: 2023-06-13</p>
      <p>
        DevBlog använder cookies för att förbättra användarupplevelsen på
        webbplatsen. I denna cookie-policy beskrivs vilka typer av cookies som
        används, hur de används och hur du som användare kan hantera dina
        cookie-inställningar.
      </p>
      <h3 class="title is-4">Vad är cookies?</h3>
      Cookies är små textfiler som placeras på din enhet när du besöker en
      webbplats. Dessa filer kan innehålla information om dina preferenser och
      användningsmönster på webbplatsen. Cookies används ofta för att förbättra
      funktionaliteten på webbplatser och ge en skräddarsydd upplevelse för
      användaren.
      <h3 class="title is-4">Vilka typer av cookies används?</h3>
      <strong>DevBlog använder följande typer av cookies:</strong>
      <ol>
        <li>
          <strong>Nödvändiga cookies:</strong> Dessa cookies är väsentliga för
          att webbplatsen ska fungera korrekt och tillhandahålla grundläggande
          funktioner som sidnavigering och åtkomst till säkra områden av
          webbplatsen. Utan dessa cookies kan vissa funktioner på webbplatsen
          vara otillgängliga.
        </li>
        <li>
          <strong>Prestanda- och analytiska cookies:</strong> Dessa cookies
          används för att samla in information om hur besökare använder
          webbplatsen, till exempel vilka sidor som besöks oftast och om
          användare möter några felmeddelanden. Informationen som samlas in är
          anonym och används för att förbättra webbplatsens prestanda och
          användarupplevelse.
        </li>
        <li>
          <strong>Funktionalitetscookies:</strong> Dessa cookies används för att
          komma ihåg dina preferenser och val på webbplatsen, till exempel ditt
          användarnamn eller språkval. Genom att använda dessa cookies kan
          webbplatsen erbjuda dig en mer personlig och anpassad upplevelse. Samt
          inloggning.
        </li>
      </ol>
      <h3 className="subtitle is-5">
        Hur kan jag hantera cookie-inställningarna?
      </h3>
      <p>
        Du kan anpassa och hantera dina cookie-inställningar genom att ändra
        inställningarna i din webbläsare. Du har möjlighet att acceptera eller
        avvisa cookies samt att radera befintliga cookies från din enhet.
        Observera att om du väljer att avvisa eller inaktivera vissa cookies kan
        vissa funktioner på webbplatsen bli otillgängliga eller fungera inte
        korrekt.
      </p>
      <p>
        För mer detaljerad information om hur du ändrar cookie-inställningarna i
        din webbläsare kan du besöka webbläsarens hjälpsida eller använda
        följande länkar:
      </p>
      <ul>
        <li>
          <a href="https://support.google.com/chrome/answer/95647">
            Cookie-inställningar i Google Chrome
          </a>
        </li>
        <li>
          <a href="https://support.mozilla.org/sv/kb/aktivera-och-inaktivera-kakor-webbplatser-installera-och-avinstallera">
            Cookie-inställningar i Mozilla Firefox
          </a>
        </li>
        <li>
          <a href="https://support.microsoft.com/sv-se/microsoft-edge/hantera-cookies-och-webbplatsdata-168dab11-0753-043d-7c16-ede5947fc64d">
            Cookie-inställningar i Microsoft Edge
          </a>
        </li>
      </ul>
      <p>
        <strong>Ändringar i cookie-policyn:</strong> Vi förbehåller oss rätten
        att uppdatera och ändra denna cookie-policy när som helst. Eventuella
        ändringar kommer att publiceras på denna sida och det är ditt ansvar som
        användare att regelbundet granska denna policy för att vara informerad
        om eventuella ändringar.
      </p>
      <p>
        <strong>Kontakta oss:</strong> Om du har frågor eller funderingar
        angående denna cookie-policy kan du kontakta oss genom info@devblog.se.
      </p>
    </div>
  );
}

export default CookiePolicy;
