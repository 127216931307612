/* eslint-disable */

import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import fb from "../script/firebase";
import { useNavigate } from "react-router-dom";
import FileUpload from "../upload";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const db = fb.firestore();
const Blogslist = db.collection("blogs");
const usersCollection = db.collection("users");

const CreateBlog = () => {
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState([]);
  const [body, setBody] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [meta, setMeta] = useState("");
  const [description, setDescription] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const currentUser = fb.auth().currentUser;
    if (currentUser) {
      usersCollection
        .doc(currentUser.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setAuthorName(doc.data().authorName);
          }
        })
        .catch((error) => {
          console.error("Error fetching author name:", error);
        });
    }
  }, []);
  const handleMetaChange = (e) => {
    setMeta(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleCategoryChange = (e) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setCategory(selectedOptions);
  };

  const handleEditorChange = (value) => {
    setBody(value);
  };

  const submit = (e) => {
    e.preventDefault();
    const currentUser = fb.auth().currentUser;
    if (currentUser) {
      const blogPost = {
        Title: title,
        Category: category,
        Body: body,
        File: fileUrl,
        createdAt: fb.firestore.Timestamp.fromDate(new Date()),
        createdBy: currentUser.uid,
        authorName: authorName,
        Description: description,
        meta: {
          value: meta,
        },
      };

      Blogslist.add(blogPost)
        .then((docRef) => {
          toast.success("Inlägget är nu sparat!");
          navigate("/dashboard");
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      console.log("Ingen användare är inloggad");
    }
  };

  const handleFileUpload = (uploadedFileUrl) => {
    setFileUrl(uploadedFileUrl);
  };

  return (
    <div className="box">
      <div className="columns">
        <div className="column is-half">
          <label className="label">Ladda upp en bild</label>
          <p />
          <FileUpload handleFileUpload={handleFileUpload} multiple={true} />
        </div>
        <div className="column is-half">
          <label className="label">Titel på inlägget</label>
          <input
            type="text"
            className="input"
            placeholder="Title"
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <label className="label">Inlägg</label>
          <ReactQuill
            value={body}
            onChange={handleEditorChange}
            placeholder="Skriv din text här"
          />

          <label className="label">Välj Kategorier</label>
          <div className="select is-multiple">
            <select multiple value={category} onChange={handleCategoryChange}>
              <option value="Angular">Angular</option>
              <option value="HTML">HTML</option>
              <option value="CSS">CSS</option>
              <option value="Vue">Vue</option>
              <option value="React">React</option>
              <option value="Linux">Linux</option>
              <option value="Databaser">Databaser</option>
              <option value="JavaScript">JavaScript</option>
              <option value="Java">Java</option>
              <option value="Node">Node</option>
            </select>
          </div>

          <div className="container-post">
            <label className="label ">SEO</label>
            <div>
              <label className="label">Meta tags</label>
              <input
                type="text"
                className="input"
                placeholder="Meta Value"
                onChange={handleMetaChange}
                required
              />
            </div>
            <div>
              <label className="label">Kort beskrivning</label>
              <input
                type="text"
                className="input"
                placeholder=" Kort beskriving"
                onChange={handleDescriptionChange}
                required
              />
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={submit}>
        <button className="button is-primary" type="submit">
          Posta Inlägget
        </button>
      </form>
    </div>
  );
};

export default CreateBlog;
