import React, { useState, useEffect } from "react";
import { auth } from "../script/firebase";
import { Link } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import { RxUpdate } from "react-icons/rx";
import trash from "../assets/social/trash.png";
import edit from "../assets/social/edit.png";
import { toast } from "react-toastify";

import fb from "../script/firebase";
const db = fb.firestore();
const Blogs = db.collection("blogs");
const Users = db.collection("users");

const Home = () => {
  const [user, setUser] = useState(null);
  const [authorName, setAuthorName] = useState("");
  const [blogslist, setBlogslist] = useState([]);

  const DeleteBlog = (id) => {
    Blogs.doc(id)
      .delete()
      .then(() => {
        toast.info("Du har tagit bort inlägget!");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  };

  useEffect(() => {
    const unsubscribe = Blogs.orderBy("createdAt", "desc")
      .limit(100)
      .onSnapshot((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        setBlogslist(data);
      });

    // Detach listener
    return unsubscribe;
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);

        Users.doc(user.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              setAuthorName(doc.data().authorName);
            } else {
              console.log("No such document!");
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      } else {
        setUser(null);
      }
    });

    // Detach listener
    return unsubscribe;
  }, []);

  return (
    <>
      <div className="box">
        <p />
        <h2 className="title">Alla Inlägg</h2>
        <table className="table is-fullwidth">
          <thead>
            {blogslist.map((blog) => (
              <tr key={blog.id}>
                <td className="subtitle is-5">
                  {blog.File && (
                    <img
                      src={blog.File}
                      alt="Thumbnail"
                      width="100"
                      height="50"
                    />
                  )}
                </td>

                <td className="subtitle is-5">{blog.Title}</td>

                <td>
                  <Link to={`/edit/${blog.id}`}>
                    <img src={edit} alt="trash" width={30} />
                  </Link>
                </td>
                <td>
                  <Link
                    onClick={() => {
                      DeleteBlog(blog.id);
                    }}
                  >
                    <img src={trash} alt="trash" width={30} />
                  </Link>
                </td>
              </tr>
            ))}
          </thead>
        </table>
      </div>
    </>
  );
};

export default Home;
