import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

const MissingPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 2000); // Adjust the delay in milliseconds (3 seconds in this example)

    return () => clearTimeout(timer); // Cleanup the timer when the component unmounts
  }, [navigate]);

  return (
    <>
      <div className="box">
        <div className="side-missing">
          <progress class="progress is-medium is-primary" max="100"></progress>
        </div>
      </div>
    </>
  );
};

export default MissingPage;
