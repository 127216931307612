import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaTrash } from "react-icons/fa";

import { toast } from "react-toastify";

import fb from "../script/firebase";
const db = fb.firestore();
const Users = db.collection("users");

const AllUser = () => {
  const [usersList, setUsersList] = useState([]);
  const [updatedRole, setUpdatedRole] = useState("");
  const deleteUser = (id) => {
    Users.doc(id)
      .delete()
      .then(() => {
        toast.info("Du har tagit bort användaren!");
      })
      .catch((error) => {
        console.error("Error removing user: ", error);
      });
  };

  const updateRole = (id, role) => {
    if (role === "Admin") {
      toast.info("Du kan inte ändra rollen för en Admin-användare till Drone.");
      return;
    }

    const newRole = role === "Admin" ? "Drone" : "Admin";

    Users.doc(id)
      .update({ role: newRole })
      .then(() => {
        toast.info("Rollen har uppdaterats!");
        setUpdatedRole("");
      })
      .catch((error) => {
        console.error("Error updating role: ", error);
      });
  };

  useEffect(() => {
    const unsubscribe = Users.onSnapshot((querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setUsersList(data);
    });

    // Detach listener
    return unsubscribe;
  }, []);

  return (
    <>
      <div className="box">
        <h2 className="title">Alla Användare</h2>
        <table className="table is-fullwidth">
          <thead>
            <td className="subtitle is-5">
              <strong>Namn</strong>
            </td>
            <td className="subtitle is-5">
              <strong>Roll</strong>
            </td>
            <td></td>
            {usersList.map((user) => (
              <tr key={user.id}>
                <td className="subtitle is-5">{user.authorName}</td>
                <td className="subtitle is-5">{user.role}</td>
                <td>
                  <Link
                    onClick={() => {
                      deleteUser(user.id);
                    }}
                  >
                    <FaTrash size={20} color="red" />
                  </Link>
                </td>
              </tr>
            ))}
          </thead>
        </table>
      </div>
    </>
  );
};

export default AllUser;
