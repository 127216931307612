import React, { useState, useEffect } from "react";
import fb from "./script/firebase";
import { Helmet } from "react-helmet";
import facebook from "./assets/social/facebook.png";
import twitter from "./assets/social/twitter.png";
import TummeUp from "./assets/social/tummenUP.png";
import linkedin from "./assets/social/linkedin.png";
import user from "./assets/social/user.png";
import cal from "./assets/social/cal.png";
import comm from "./assets/social/comments.png";
import TummeNer from "./assets/social/tummenNer.png";
import back from "./assets/social/back.png";
import forward from "./assets/social/forward.png";

import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

import firebase from "firebase/compat/app";
import defaultImage from "./assets/noimage.png";

import { FaUser, FaCalendar } from "react-icons/fa";
import { format } from "date-fns";

const db = fb.firestore();
const Blogs = db.collection("blogs");
const Comments = db.collection("comments");
const Users = db.collection("users");
const Likes = db.collection("likes");
const PAGE_SIZE = 3;

const Bloglist = () => {
  const [blogslist, setBlogsList] = useState([]);

  const [totalComments, setTotalComments] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [totalLikes, setTotalLikes] = useState({});
  const [likedBlogs, setLikedBlogs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    let query = Blogs;

    if (searchQuery) {
      query = query
        .orderBy("Title")
        .startAt(searchQuery)
        .endAt(searchQuery + "\uf8ff");
    } else {
      query = query.orderBy("createdAt", "desc");
    }
    if (selectedCategory) {
      query = query.where("Category", "==", parseInt(selectedCategory));
    }

    const unsubscribe = query.onSnapshot((querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        isLiked: likedBlogs.includes(doc.id), // Check if blog is liked
      }));

      const fetchLikesPromises = data.map((blog) =>
        Likes.doc(blog.id)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const likesData = doc.data();
              return likesData.likes || 0;
            } else {
              return 0;
            }
          })
          .catch((error) => {
            console.error("Error fetching likes: ", error);
            return 0;
          })
      );

      Promise.all(fetchLikesPromises)
        .then((likesCounts) => {
          const totalLikesData = {};
          likesCounts.forEach((likes, index) => {
            const blogId = data[index].id;
            totalLikesData[blogId] = likes;
          });
          setTotalLikes(totalLikesData);
        })
        .catch((error) => {
          console.error("Error fetching likes data: ", error);
        });

      // Fetch user data for each blog post
      const fetchUserPromises = data.map((blog) =>
        getUserData(blog.createdBy).then((userData) => {
          blog.authorName = userData ? userData.authorName : "Unknown";
          return blog;
        })
      );

      Promise.all(fetchUserPromises)
        .then((blogsWithData) => {
          setBlogsList(blogsWithData);

          setIsLoading(false); // Set loading state to false
        })
        .catch((error) => {
          console.error("Error fetching user data: ", error);
          setIsLoading(false); // Set loading state to false (in case of error)
        });
    });

    return unsubscribe;
  }, [likedBlogs, searchQuery, selectedCategory]);
  useEffect(() => {
    // Fetch blogs from Firebase Firestore
    const fetchBlogs = async () => {
      setIsLoading(true);

      try {
        const blogsRef = firebase.firestore().collection("blogs");
        const snapshot = await blogsRef.orderBy("createdAt", "desc").get();
        const blogData = snapshot.docs.map((doc) => doc.data());

        setBlogsList(blogData);
        setTotalPages(Math.ceil(blogData.length / PAGE_SIZE));
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, []);

  useEffect(() => {
    const fetchComments = async () => {
      const commentCounts = {};

      for (const blog of blogslist) {
        try {
          const querySnapshot = await Comments.where(
            "postId",
            "==",
            blog.id
          ).get();
          const count = querySnapshot.size;
          commentCounts[blog.id] = count;
        } catch (error) {
          console.error("Error getting comments: ", error);
        }
      }

      setTotalComments(commentCounts);
    };

    fetchComments();
  }, [blogslist]);

  const getUserData = (userId) => {
    return Users.doc(userId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data();
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.error("Error fetching user data: ", error);
        return null;
      });
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const currentBlogs = blogslist.slice(startIndex, endIndex);

  const isBlogLiked = (blogId) => {
    return likedBlogs.includes(blogId);
  };

  const handleLike = (blogId) => {
    // Update likedBlogs state
    setLikedBlogs((prevLikedBlogs) => [...prevLikedBlogs, blogId]);

    setTotalLikes((prevTotalLikes) => ({
      ...prevTotalLikes,
      [blogId]: (prevTotalLikes[blogId] || 0) + 1,
    }));

    // Save the like to Firestore
    Likes.doc(blogId)
      .set(
        { likes: firebase.firestore.FieldValue.increment(1) },
        { merge: true }
      )

      .then(() => {
        console.log("Like saved to Firestore");
      })
      .catch((error) => {
        console.error("Error saving like to Firestore: ", error);
      });
  };

  const handleUnlike = (blogId) => {
    // Update likedBlogs state
    setLikedBlogs((prevLikedBlogs) =>
      prevLikedBlogs.filter((likedBlogId) => likedBlogId !== blogId)
    );

    // Update totalLikes state
    setTotalLikes((prevTotalLikes) => ({
      ...prevTotalLikes,
      [blogId]: (prevTotalLikes[blogId] || 0) - 1,
    }));

    // Update the like count in Firestore
    Likes.doc(blogId)
      .set(
        { likes: firebase.firestore.FieldValue.increment(-1) },
        { merge: true }
      )
      .then(() => {
        console.log("Like count updated in Firestore");
      })
      .catch((error) => {
        console.error("Error updating like count in Firestore: ", error);
      });
  };
  const handleShareFacebook = (title, blogId) => {
    const url = `https://blogg.holmdeveloper.se/show/${blogId}`;
    const encodedUrl = encodeURIComponent(url);
    const shareText = encodeURIComponent(title);
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;

    window.open(shareUrl, "_blank");
  };

  const handleShareTwitter = (title, blogId) => {
    const url = `https://blogg.holmdeveloper.se/show/${blogId}`;
    const encodedUrl = encodeURIComponent(url);
    const shareText = encodeURIComponent(title);
    const shareUrl = `https://twitter.com/intent/tweet?text=${shareText}&url=${encodedUrl}`;

    window.open(shareUrl, "_blank");
  };
  const handleShareLinkedIn = (title, blogId) => {
    const url = `https://blogg.holmdeveloper.se/show/${blogId}`;
    const encodedUrl = encodeURIComponent(url);
    const shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${title}`;

    window.open(shareUrl, "_blank");
  };
  console.log(currentBlogs);
  return (
    <div className="box">
      <Helmet>
        <title>DevBlog - Your portal to becoming a better developer</title>
        <meta
          name="description"
          content="Sidan för dig som älskar utveckling, här finns allt för dig!"
        />
        <meta
          name="keywords"
          content="javascript, linux, java, Typescript, php, html, windows, css"
        />
      </Helmet>
      <style>
        {`
          .no-blogs {
          text-align: center;
          margin-top: 20px;
          font-size: 18px;
        
      `}
      </style>
      <div>
        <div className="title">Alla Inlägg</div>

        {isLoading ? ( // Display loading state
          <progress class="progress is-small is-info" max="100"></progress>
        ) : currentBlogs.length === 0 ? (
          <div className="no-blogs">Inga blogginlägg tillgängliga</div>
        ) : (
          currentBlogs.map((blog) => (
            <div key={blog.id}>
              <div className="columns">
                <div className="column is-half">
                  {blog.File ? (
                    <img src={blog.File} alt="front" className="img-front" />
                  ) : (
                    <img
                      src={defaultImage}
                      alt="default"
                      className="img-front"
                    />
                  )}
                  <div className="post-top">
                    <div className="subtitle is-5">
                      <span class="icon-text">
                        <span class="icon">
                          <img src={user} alt="user" width={25} />
                        </span>
                        <span>
                          <strong>{blog.authorName}</strong>
                        </span>
                      </span>
                    </div>

                    <div className="subtitle is-5">
                      <span class="icon-text">
                        <span class="icon">
                          <img src={cal} alt="cal" width={25} />
                        </span>
                        <span>
                          <strong>
                            {format(blog.createdAt.toDate(), "yyyy-MM-dd")}
                          </strong>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="column">
                  <div className="">
                    <div>
                      <Link to={"/show/" + blog.id}>
                        {" "}
                        <div className="title">{blog.Title}</div>
                      </Link>
                      <div className="content-1">
                        <div
                          className="subtitle is-5"
                          dangerouslySetInnerHTML={{
                            __html: blog.Body.substring(0, 1000),
                          }}
                        />
                        <div className="cont">
                          <div className="subtitle is-5">
                            <strong> Kategorier:</strong>{" "}
                            {blog.Category && Array.isArray(blog.Category) ? (
                              blog.Category.map((category, index) => (
                                <div
                                  key={index}
                                  className="tag is-info is-medium"
                                  style={{
                                    marginRight: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  {category}
                                </div>
                              ))
                            ) : (
                              <div className="tag is-alert is-medium">
                                Inga kategorier tillagda
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="social">
                          {isBlogLiked(blog.id) ? (
                            <div
                              className="likunlike"
                              onClick={() => handleUnlike(blog.id)}
                            >
                              <img
                                src={TummeNer}
                                alt="Tummen ner"
                                className="socialIcons-front"
                              />
                            </div>
                          ) : (
                            <div
                              className="likunlike"
                              onClick={() => handleLike(blog.id)}
                            >
                              <img
                                src={TummeUp}
                                alt="TummeUp"
                                className="socialIcons-front"
                              />
                            </div>
                          )}
                          <div className="counter-like">
                            {totalLikes[blog.id] || 0}{" "}
                          </div>

                          <div
                            onClick={() =>
                              handleShareFacebook(blog.Title, blog.id)
                            }
                          >
                            <img
                              src={facebook}
                              alt="facebook"
                              className="socialIcons-front"
                            />
                          </div>
                          <div
                            onClick={() =>
                              handleShareTwitter(blog.Title, blog.id)
                            }
                          >
                            <img
                              src={twitter}
                              alt="facebook"
                              className="socialIcons-front"
                            />
                          </div>
                          <div
                            onClick={() =>
                              handleShareLinkedIn(blog.Title, blog.id)
                            }
                          >
                            <img
                              src={linkedin}
                              alt="linkedin"
                              className="socialIcons-front"
                            />
                          </div>

                          {totalComments[blog.id] > 0 && (
                            <div className="comment-count">
                              {totalComments[blog.id]}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="block"></div>
            </div>
          ))
        )}

        {blogslist.length > 0 && (
          <div className="buttons ">
            <button
              className="button is-white"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <img src={back} alt="back" />
              {""}
            </button>
            <button
              className="button is-white"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <img src={forward} alt="next" />
            </button>
          </div>
        )}
        {blogslist.length > 0 && (
          <div className="page-numbering">
            Sida {currentPage} av {totalPages}
          </div>
        )}
      </div>
    </div>
  );
};

export default Bloglist;
