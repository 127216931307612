import React, { useContext, useState } from "react";
import logostor from "../assets/logostor.png";
import { signOut } from "firebase/auth";
import { Link } from "react-router-dom";
import { CgMenuGridR, CgCloseR } from "react-icons/cg";
import { AuthContext } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import { auth } from "../script/firebase";
import { toast } from "react-toastify";
import "../css/navbar.css";

const Navbar = () => {
  const [isActive, setisActive] = useState(false);
  const { isLoggedIn, userRole, logout } = useContext(AuthContext);
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);

  const handleClick = () => {
    setisActive(false);
  };
  const navigate = useNavigate();
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        toast.success("Du är utloggad", {
          autoClose: 2000,
        });
        logout();
        navigate("/");
      })
      .catch((error) => {
        // An error happened.
      })
      .finally(() => {
        setLogoutModalOpen(false);
      });
  };

  const toggleLogoutModal = () => {
    setLogoutModalOpen(!isLogoutModalOpen);
  };

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <div className="logo-my">
          <Link to="/">
            <img src={logostor} alt="Logo" />
          </Link>
        </div>
        <a
          role="button"
          className={`navbar-burger ${isActive ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded={isActive ? "true" : "false"}
          onClick={() => {
            setisActive(!isActive);
          }}
        >
          <div className="menu" aria-label="Main Menu">
            {isActive ? (
              <CgCloseR className="close" />
            ) : (
              <CgMenuGridR className="bar" />
            )}
          </div>
        </a>
      </div>
      <div className={`navbar-menu ${isActive ? "is-active" : ""}`}>
        <div className="navbar-end">
          {isLoggedIn ? (
            <>
              {userRole === "Admin" && (
                <div className="navbar-item">
                  <Link to="/dashboard" className="navbar-item">
                    <div className="panel-text" onClick={handleClick}>
                      Alla Inlägg
                    </div>
                  </Link>
                  <Link to="/users" className="navbar-item">
                    <div className="panel-text" onClick={handleClick}>
                      Användare
                    </div>
                  </Link>
                  <Link to="/create" className="navbar-item">
                    <div className="panel-text" onClick={handleClick}>
                      Skapa ett inlägg
                    </div>
                  </Link>
                  <Link to="/profil" className="navbar-item">
                    <div className="panel-text" onClick={handleClick}>
                      Profil
                    </div>
                  </Link>
                </div>
              )}

              {userRole === "Drone" && (
                <div className="navbar-item">
                  <Link to="/" className="navbar-item">
                    <div className="panel-text" onClick={handleClick}>
                      Arkiv
                    </div>
                  </Link>
                  <Link to="/profil" className="navbar-item">
                    <div className="panel-text" onClick={handleClick}>
                      Profil
                    </div>
                  </Link>
                </div>
              )}

              <div className="navbar-item">
                <button className="button" onClick={toggleLogoutModal}>
                  Logga ut
                </button>
              </div>
            </>
          ) : (
            <div className="navbar-item">
              <Link to="/signup" className="navbar-item">
                <div className="panel-text">Bli Medlem</div>
              </Link>
              <Link to="/login" className="navbar-item">
                <div className="panel-text">Logga In</div>
              </Link>
            </div>
          )}
        </div>
      </div>

      {isLogoutModalOpen && (
        <div className="logout-modal">
          <div className="logout-content">
            <div
              className="title"
              style={{ color: "white", textAlign: "center" }}
            >
              Bekräfta
            </div>
            <div className="suptitle is-5" style={{ textAlign: "center" }}>
              Är du säker på att du vill logga ut?
            </div>
            <div className="modal-buttons">
              <button
                className="button is-warning"
                style={{ color: "black", fontWeight: "bold" }}
                onClick={toggleLogoutModal}
              >
                Ångra
              </button>
              <button
                className="button is-success"
                style={{ color: "black", fontWeight: "bold" }}
                onClick={handleLogout}
              >
                Logga ut
              </button>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
