import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider, AuthContext } from "./components/AuthContext";
import CreateBlog from "./components/blogs/create";
import BlogslistView from "./components/bloglist";
import BlogView from "./components/blogs/show";
import BlogEdit from "./components/blogs/edit";
import Login from "./components/pages/login";
import Home from "./components/pages/home";
import Signup from "./components/pages/signup";
import CookiePolicy from "./components/pages/cookie-policy";
import Navbar from "./components/pages/navbar";
import Footer from "./components/pages/footer";
import Profile from "./components/pages/profile";
import EditProfile from "./components/pages/userEdit";
import MissingPage from "./components/pages/missingpage";
import Popup from "./components/pages/Cookie";
import AllUser from "./components/pages/users";
import fb, { auth } from "./components/script/firebase"; // Import 'auth' from firebase

const db = fb.firestore();
const Users = db.collection("users");

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
        Users.doc(user.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const userData = doc.data();
              setUserRole(userData.role);
            } else {
              console.log("No such document!");
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      } else {
        setIsLoggedIn(false);
        setUserRole(null);
      }
    });

    // Detach listener
    return unsubscribe;
  }, []);

  return (
    <>
      <div className="container">
        <AuthProvider>
          <AuthContext.Provider value={{ isLoggedIn, userRole }}>
            <Router>
              <Navbar />
              <Routes>
                <Route path="/cookie-policy" element={<CookiePolicy />} />
                <Route path="/" element={<BlogslistView />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/login" element={<Login />} />
                <Route path="/show/:id" element={<BlogView />} />

                {isLoggedIn && (
                  <>
                    {userRole === "Admin" && (
                      <>
                        <Route path="/dashboard" element={<Home />} />
                        <Route path="/profil" element={<Profile />} />

                        <Route path="/create" element={<CreateBlog />} />
                        <Route path="/users" element={<AllUser />} />

                        <Route path="/edit/:id" element={<BlogEdit />} />
                        <Route path="/edit-profil" element={<EditProfile />} />
                      </>
                    )}

                    {userRole === "Drone" && (
                      <>
                        <Route path="/profil" element={<Profile />} />
                        <Route path="/edit-profil" element={<EditProfile />} />
                      </>
                    )}
                  </>
                )}

                <Route path="*" element={<MissingPage />} />
              </Routes>
              <Footer />
            </Router>
          </AuthContext.Provider>
        </AuthProvider>
      </div>
      <Popup />
    </>
  );
}

export default App;
