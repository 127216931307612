import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import facebook from "../assets/social/facebook.png";
import twitter from "../assets/social/twitter.png";
import linkedin from "../assets/social/linkedin.png";
import trash from "../assets/social/trash.png";
import replay from "../assets/social/replay.png";
import cal from "../assets/social/cal.png";
import user from "../assets/social/user.png";
import come from "../assets/social/comments.png";
import back from "../assets/social/back.png";

import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import { format } from "date-fns";
import { AiFillFacebook, AiFillTwitterSquare } from "react-icons/ai";
import {
  FaUser,
  FaComments,
  FaCalendar,
  FaReply,
  FaTrash,
  FaBackspace,
} from "react-icons/fa";

import fb from "../script/firebase";
const db = fb.firestore();
const Blogs = db.collection("blogs");
const Comments = db.collection("comments");

const BlogView = () => {
  const { id } = useParams();

  const [blog, setBlog] = useState([]);
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [commentName, setCommentName] = useState("");
  const [commentEmail, setCommentEmail] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [replyCommentId, setReplyCommentId] = useState("");
  const [replyCommentName, setReplyCommentName] = useState("");
  const [replyCommentEmail, setReplyCommentEmail] = useState("");
  const [replyCommentText, setReplyCommentText] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    const fetchCurrentUser = async () => {
      const user = fb.auth().currentUser;
      if (user) {
        const userDoc = await db.collection("users").doc(user.uid).get();
        const userData = userDoc.data();
        if (userData && userData.role === "Admin") {
          user.isAdmin = true;
        }
        user.authorName = userData && userData.authorName; // Get authorName from user database
      }
      setCurrentUser(user);
    };

    fetchCurrentUser();
    // ...
  }, [id]);

  useEffect(() => {
    Blogs.doc(id)
      .get()
      .then((snapshot) => {
        const data = snapshot.data();
        setBlog(data);
        document.title = data.Title;
      });

    getCommentsForPost(id);
  }, [id]);

  const getCommentsForPost = (blogId) => {
    Comments.where("postId", "==", blogId)
      .get()
      .then((querySnapshot) => {
        const comments = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setComments(comments);
      })
      .catch((error) => {
        console.error("Error getting comments: ", error);
      });
  };
  const handleShareFacebook = () => {
    const shareText = encodeURIComponent(blog.Title);
    const shareDescription = encodeURIComponent(blog.Description);
    const shareUrl = encodeURIComponent(window.location.href);
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&quote=${shareText}&description=${shareDescription}`;
    window.open(facebookShareUrl, "_blank");
  };

  const handleShareTwitter = () => {
    const shareText = encodeURIComponent(blog.Title);
    const desText = encodeURIComponent(blog.Description);
    const shareUrl = encodeURIComponent(window.location.href);
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${shareText}%0A${desText}&url=${shareUrl}`;
    window.open(twitterShareUrl, "_blank");
  };
  const handleShareLinkedIn = () => {
    const shareText = encodeURIComponent(blog.Title);
    const shareDescription = encodeURIComponent(blog.Description);
    const shareUrl = encodeURIComponent(window.location.href);
    const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}&title=${shareText}&summary=${shareDescription}`;
    window.open(linkedInShareUrl, "_blank");
  };
  const handlePostComment = () => {
    if (isCaptchaVerified) {
      if (currentUser) {
        const commentData = {
          postId: id,
          comment: commentText,
          commentName: currentUser.authorName,
          commentEmail: currentUser.email,
          createdAt: fb.firestore.Timestamp.fromDate(new Date()),
          authorUid: currentUser.uid,
        };

        Comments.add(commentData)
          .then(() => {
            setCommentText("");
            getCommentsForPost(id);
          })
          .catch((error) => {
            console.error("Error posting comment: ", error);
          });
      } else {
        toast.info("Du måste logga in för att kunna kommentera.");
      }
    } else {
      toast.info("Vänligen verifiera dig själv med reCAPTCHA.");
    }
  };

  const handleCaptchaVerification = (response) => {
    setIsCaptchaVerified(true);
  };

  const handleDeleteComment = (commentId, authorUid) => {
    if (currentUser && (currentUser.uid === authorUid || currentUser.isAdmin)) {
      Comments.doc(commentId)
        .delete()
        .then(() => {
          getCommentsForPost(id);
          toast.info("Kommentar är bortagen!  ");
        })
        .catch((error) => {
          console.error("Error deleting comment: ", error);
        });
    } else {
      console.log("User is not authorized to delete this comment.");
    }
  };

  const handleReplyComment = (commentId, commentAuthorUid) => {
    setReplyCommentId(commentId);
    setReplyCommentName("");
    setReplyCommentEmail("");
    setReplyCommentText("");
  };

  const handlePostReply = () => {
    if (isCaptchaVerified) {
      const parentComment = comments.find(
        (comment) => comment.id === replyCommentId
      );
      const parentCommentAuthorUid = parentComment
        ? parentComment.authorUid
        : "";

      const replyData = {
        postId: id,
        comment: replyCommentText,
        commentName: currentUser.authorName,
        commentEmail: currentUser.email,
        authorUid: currentUser.uid,
        createdAt: fb.firestore.Timestamp.fromDate(new Date()),
        parentCommentId: replyCommentId,
        parentCommentAuthorUid: parentCommentAuthorUid,
      };

      Comments.add(replyData)
        .then(() => {
          setReplyCommentId("");
          setReplyCommentName("");
          setReplyCommentEmail("");
          setReplyCommentText("");
          getCommentsForPost(id);
        })
        .catch((error) => {
          console.error("Error posting reply: ", error);
        });
    } else {
      toast.info("Du måste verifiera dig med reCAPTCHA.");
    }
  };

  return (
    <div className="box">
      <Helmet>
        <title>{blog.Title}</title>
        <meta name="description" content={blog.Description} />
        <meta name="keywords" content={blog.meta} />
      </Helmet>
      <div className="title">{blog.Title}</div>
      <div className="columns">
        <div className="img-front-full-post">
          <img
            src={blog.File}
            alt="front"
            style={{ width: "90%", borderRadius: "10px" }}
          />
        </div>
      </div>
      <div className="body-post">
        <div className="post-top-all">
          <div className="subtitle is-5">
            <span class="icon-text">
              <span class="icon">
                <img src={user} alt="user" width={25} />
              </span>
              <span>
                {" "}
                <strong>{blog.authorName}</strong>
              </span>
            </span>
          </div>

          <div className="subtitle is-5">
            <span class="icon-text">
              <span class="icon">
                <img src={cal} alt="cal" width={25} />
              </span>
              <span>
                <strong>
                  {blog.createdAt &&
                    format(blog.createdAt.toDate(), "yyyy-MM-dd")}
                </strong>
              </span>
            </span>
          </div>
        </div>
        <div
          className="subtitle is-5"
          dangerouslySetInnerHTML={{
            __html: blog.Body,
          }}
        />

        <div className="subtitle is-5">
          <strong> Kategorier:</strong>{" "}
          {blog.Category && Array.isArray(blog.Category) ? (
            blog.Category.map((category, index) => (
              <div
                key={index}
                className="tag is-info is-medium"
                style={{
                  marginRight: "5px",
                  marginBottom: "5px",
                }}
              >
                {category}
              </div>
            ))
          ) : (
            <div className="tag is-alert is-medium">
              Inga kategorier tillagda
            </div>
          )}
          <div className="social">
            <div onClick={handleShareFacebook}>
              <img src={facebook} alt="facebook" className="socialIcons" />
            </div>
            <div onClick={handleShareTwitter}>
              <img src={twitter} alt="twitter" className="socialIcons" />
            </div>
            <div onClick={handleShareLinkedIn}>
              <img src={linkedin} alt="LinkedIn" className="socialIcons" />
            </div>
          </div>
        </div>
      </div>

      <hr></hr>
      <div className="subtitle is-5">
        <Link to="/" className="subtitle is-5">
          <img src={back} alt="back" />
        </Link>
      </div>
      <div className="box">
        <div className="headComment">
          <div>
            <img src={come} alt="comments" width={35} />
          </div>
          <div className="title">Kommentarer</div>
        </div>

        <div className="columns">
          <div className="column is-half">
            {comments
              .filter((comment) => !comment.parentCommentId)
              .map((comment) => (
                <div
                  key={comment.id}
                  className="box"
                  style={{ backgroundColor: "#f4f4f4", marginBottom: "10px" }}
                >
                  <div className="comment-mail-and-name">
                    <img src={user} alt="user" width={20} />
                    <a href={`mailto:${comment.commentEmail}`}>
                      {comment.commentName}
                    </a>
                  </div>
                  <div className="comment-text">
                    <div className="comment-comment">
                      <div>
                        {" "}
                        <img src={come} alt="user" width={20} />
                      </div>
                      <div>{comment.comment}</div>
                    </div>
                  </div>
                  <img src={cal} alt="user" width={20} />
                  {comment.createdAt &&
                    format(comment.createdAt.toDate(), "yyyy-MM-dd")}
                  <hr style={{ backgroundColor: "#1684ac" }} />{" "}
                  <div className="replay">
                    {currentUser && currentUser.isAdmin && (
                      <Link
                        className="delete-comment-button"
                        onClick={() =>
                          handleDeleteComment(comment.id, comment.authorUid)
                        }
                      >
                        <img src={trash} alt="trash" width={25} />
                      </Link>
                    )}

                    {currentUser && (
                      <Link
                        className="reply-comment-button"
                        onClick={() =>
                          handleReplyComment(comment.id, comment.authorUid)
                        }
                      >
                        <img src={replay} alt="replay" width={25} />
                      </Link>
                    )}
                  </div>
                  {replyCommentId === comment.id && (
                    <div
                      className="reply-box"
                      style={{
                        backgroundColor: "#fff",
                        padding: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <div className="field">
                        <label className="label">Namn</label>
                        <div className="control">
                          {currentUser ? (
                            <input
                              className="input"
                              type="text"
                              placeholder="Ditt namn"
                              value={replyCommentName || currentUser.authorName}
                              onChange={(e) =>
                                setReplyCommentName(e.target.value)
                              }
                              disabled
                            />
                          ) : (
                            <input
                              className="input"
                              type="text"
                              placeholder="Ditt namn"
                              value={replyCommentName}
                              onChange={(e) =>
                                setReplyCommentName(e.target.value)
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">E-Post</label>
                        <div className="control">
                          {currentUser ? (
                            <input
                              className="input"
                              type="email"
                              placeholder="Epost"
                              value={replyCommentEmail || currentUser.email}
                              onChange={(e) =>
                                setReplyCommentName(e.target.value)
                              }
                              disabled
                            />
                          ) : (
                            <input
                              className="input"
                              type="email"
                              placeholder="E-post"
                              value={replyCommentEmail}
                              onChange={(e) =>
                                setReplyCommentEmail(e.target.value)
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Kommentar</label>
                        <div className="control">
                          <textarea
                            className="textarea"
                            placeholder="Kommentar"
                            value={replyCommentText}
                            onChange={(e) =>
                              setReplyCommentText(e.target.value)
                            }
                          ></textarea>
                        </div>
                      </div>
                      <div className="field">
                        <div className="g-recaptcha">
                          <ReCAPTCHA
                            sitekey="6LcXV1ImAAAAAIAIsLuir-re-vguCTi1Ch4dMrB5"
                            onChange={handleCaptchaVerification}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <div className="control">
                          <button
                            className="button is-primary"
                            onClick={handlePostReply}
                          >
                            Svara
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {comments
                    .filter((reply) => reply.parentCommentId === comment.id)
                    .map((reply) => (
                      <div
                        key={reply.id}
                        className="box"
                        style={{
                          backgroundColor: "#fff",
                          marginBottom: "10px",
                          marginLeft: "40px",
                        }}
                      >
                        <div className="comment-mail-and-name">
                          <img src={user} alt="user" width={20} />
                          <a href={`mailto:${reply.commentEmail}`}>
                            {reply.commentName}
                          </a>
                        </div>
                        <div className="comment-text">
                          <div className="comment-comment">
                            <div>
                              {" "}
                              <img src={come} alt="user" width={20} />
                            </div>
                            <div>{reply.comment}</div>
                          </div>
                        </div>
                        <img src={cal} alt="user" width={20} />
                        {reply.createdAt &&
                          format(reply.createdAt.toDate(), "yyyy-MM-dd")}
                        <hr style={{ backgroundColor: "#1684ac" }} />{" "}
                        <div className="replay">
                          {currentUser &&
                            (currentUser.isAdmin ||
                              currentUser.uid === reply.authorUid) && (
                              <Link
                                className="delete-comment-button"
                                onClick={() => handleDeleteComment(reply.id)}
                              >
                                <img src={trash} alt="trash" width={25} />
                              </Link>
                            )}
                        </div>
                      </div>
                    ))}
                </div>
              ))}
          </div>
          {currentUser && (
            <div className="column is-half">
              <div className="field">
                <div>
                  {currentUser ? (
                    <input
                      className="input"
                      type="hidden"
                      placeholder="Ditt namn"
                      value={replyCommentName || currentUser.authorName}
                      onChange={(e) => setReplyCommentName(e.target.value)}
                      disabled
                    />
                  ) : (
                    <input
                      className="input"
                      type="hidden"
                      placeholder="Ditt namn"
                      value={replyCommentName}
                      onChange={(e) => setReplyCommentName(e.target.value)}
                    />
                  )}
                </div>
              </div>
              <div className="field">
                <div className="control">
                  {currentUser ? (
                    <input
                      className="input"
                      type="hidden"
                      placeholder="Epost"
                      value={replyCommentEmail || currentUser.email}
                      onChange={(e) => setReplyCommentName(e.target.value)}
                      disabled
                    />
                  ) : (
                    <input
                      className="input"
                      type="email"
                      placeholder="E-post"
                      value={replyCommentEmail}
                      onChange={(e) => setReplyCommentEmail(e.target.value)}
                    />
                  )}
                </div>
              </div>
              <div className="field">
                <label className="label">Kommentar</label>
                <div className="control">
                  <textarea
                    className="textarea"
                    placeholder="Kommentar"
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <ReCAPTCHA
                    sitekey="6LcXV1ImAAAAAIAIsLuir-re-vguCTi1Ch4dMrB5"
                    onChange={handleCaptchaVerification}
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button
                    className="button is-primary"
                    onClick={handlePostComment}
                  >
                    Posta Kommentar
                  </button>
                </div>
              </div>
            </div>
          )}
          {!currentUser && (
            <div className="box" style={{ marginTop: "10px" }}>
              <div className="subtitle is-5" style={{ margin: "20px" }}>
                För att kommentera måste du vara inloggad. Klicka nedan för att
                logga in:
              </div>
              <div className="field">
                <div className="login-button">
                  <Link to="/login" className="button is-primary">
                    Logga in
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default BlogView;
