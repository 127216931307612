import React, { useState, useEffect } from "react";
import { auth } from "../script/firebase";
import { Link } from "react-router-dom";
import edit from "../assets/social/edit.png";

import fb from "../script/firebase";

const db = fb.firestore();
const Users = db.collection("users");

const Profile = () => {
  const [user, setUser] = useState(null);
  const [authorName, setAuthorName] = useState("");
  const [info, setInfo] = useState("");
  const [userRole, setUserRole] = useState("");
  const [email, setEmail] = useState("");
  const [link, setLink] = useState("");
  const [linkTitle, setLinkTitle] = useState("");
  const [git, setGit] = useState("");
  const [gitTitle, setGitTitle] = useState("");

  const [fileUrl, setFileUrl] = useState("");
  const AccountImage = userRole === "Admin" ? "admin.png" : "drone.png";
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        setFileUrl(user.photoURL);
        setInfo(user.info);
        setEmail(user.email);
        setLink(user.link);
        setLinkTitle(user.linkTitle);
        setGit(user.git);
        setGitTitle(user.gitTitle);
        setUserRole(user.userRole);
        Users.doc(user.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              setAuthorName(doc.data().authorName);
              setFileUrl(doc.data().FileUpload);
              setInfo(doc.data().info);
              setLink(doc.data().link);
              setLinkTitle(doc.data().linkTitle);
              setGit(doc.data().git);
              setGitTitle(doc.data().gitTitle);
              setEmail(doc.data().email);
              setUserRole(doc.data().role);
            } else {
              console.log("No such document!");
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      } else {
        setUser(null);
      }
    });

    // Detach listener
    return unsubscribe;
  }, []);

  return (
    <>
      <div className="box">
        <div className="columns">
          <div className="column is-half">
            <h2 className="title">Profil</h2>
            <div className="profil-img">
              {fileUrl && (
                <img className="profil-fulpage" src={fileUrl} alt="profil" />
              )}
            </div>
            <p />
          </div>

          <div className="column is-half">
            <div
              className="card custom-card"
              style={{
                backgroundColor: "#f4f4f4",
                color: "black",
                padding: "4%",
              }}
            >
              {" "}
              <Link to="/edit-profil/">
                <div className="edit">
                  <img src={edit} alt="edit" />
                </div>
              </Link>
              <div className="profil">
                <h3 className="title">{authorName}</h3>
              </div>
              <div className="space-profil">
                <h3 className="subtitle is-5">
                  <a href={`mailto:${user ? user.email : ""}`}>
                    {user ? user.email : ""}
                  </a>
                </h3>
                <div className="box">
                  <div className="subtitle is-5">
                    <div className="profil-info">
                      {link && (
                        <>
                          <strong>Hemsida</strong>{" "}
                          <a href={link}>{linkTitle}</a>
                        </>
                      )}
                      {git && (
                        <>
                          <strong>Git</strong> <a href={git}>{gitTitle}</a>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="box">
                <h3 className="subtitle is-5">
                  <p /> {info}
                </h3>
              </div>
              {fileUrl && (
                <img
                  className="account"
                  src={require(`../assets/${AccountImage}`)}
                  alt="profil"
                />
              )}
              <p />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
