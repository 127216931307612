import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="box" style={{ backgroundColor: "#f4f4f4" }}>
      <div class="content has-text-centered">
        <p>
          © {new Date().getFullYear()} DevBlog & Holm Developer. Alla
          rättigheter förbehållna.
        </p>
        <div className="footer-links">
          <div>
            <Link to="/cookie-policy">Cookie Policy</Link>
          </div>
          <div>
            <a href="https://www.holmdeveloper.se">Holm Developer</a>
          </div>
          <div>
            <a href="https://www.facebook.com/holmdev">Facebook</a>
          </div>
          <div>
            <a href="https://www.instagram.com/holmdev">Instagram</a>
          </div>
          <div>
            <a href="https://www.gitlab.com/holmdev">GitLab</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
