import React, { useState, useEffect } from "react";
import { auth } from "../script/firebase";
import { toast } from "react-toastify";
import FileUpload from "../uploadProfil";
import { useNavigate } from "react-router-dom";

import fb from "../script/firebase";

const db = fb.firestore();
const Users = db.collection("users");

const EditProfile = () => {
  const [user, setUser] = useState(null);
  const [authorName, setAuthorName] = useState("");
  const [info, setInfo] = useState("");

  const [email, setEmail] = useState("");
  const [link, setLink] = useState("");
  const [linkTitle, setLinkTitle] = useState("");
  const [git, setGit] = useState("");
  const [gitTitle, setGitTitle] = useState("");

  const [fileUrl, setFileUrl] = useState("");
  const [userRole, setUserRole] = useState(""); // Added userRole state
  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        setFileUrl(user.photoURL);
        setInfo(user.info);
        setEmail(user.email);
        setLink(user.link);
        setLinkTitle(user.linkTitle);
        setGit(user.git);
        setGitTitle(user.gitTitle);
        setUserRole(user.userRole);
        Users.doc(user.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              setAuthorName(doc.data().authorName);
              setFileUrl(doc.data().FileUpload);
              setInfo(doc.data().info);
              setLink(doc.data().link);
              setLinkTitle(doc.data().linkTitle);
              setGit(doc.data().git);
              setGitTitle(doc.data().gitTitle);
              setEmail(doc.data().email);
              setUserRole(doc.data().role); // Fetching user role from the database
            } else {
              console.log("No such document!");
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      } else {
        setUser(null);
      }
    });

    // Detach listener
    return unsubscribe;
  }, []);

  const handleNameChange = (e) => {
    setAuthorName(e.target.value);
  };

  const handleInfoChange = (e) => {
    setInfo(e.target.value);
  };
  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };
  const handleLinkTitleChange = (e) => {
    setLinkTitle(e.target.value);
  };
  const handleGitChange = (e) => {
    setGit(e.target.value);
  };
  const handleGitTitleChange = (e) => {
    setGitTitle(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleAccountChange = (e) => {
    setUserRole(e.target.value);
  };

  const handleUpdate = () => {
    if (info) {
      Users.doc(user.uid)
        .update({
          authorName: authorName,
          info: info,
          link: link,
          linkTitle: linkTitle,
          git: git,
          gitTitle: gitTitle,
          userRole: userRole,
        })
        .then(() => {
          console.log("Profile updated successfully");
          toast.success("Din profil är uppaterad");
          navigate("/profil");
        })
        .catch((error) => {
          console.error("Error updating profile: ", error);
        });
    } else {
      console.log("Info value is undefined");
    }
  };

  const handleFileUpload = (uploadedFileUrl) => {
    Users.doc(user.uid)
      .update({ FileUpload: uploadedFileUrl })
      .then(() => {
        console.log("Profile image updated successfully");
        setFileUrl(uploadedFileUrl);
      })
      .catch((error) => {
        console.error("Error updating profile image: ", error);
      });
  };

  const isAdmin = userRole === "Admin";
  const isDrone = userRole === "Drone";

  return (
    <>
      <div className="box">
        <div className="columns is-multiline">
          <div className="column is-full-mobile is-half-tablet is-half-desktop">
            <h2 className="title">Ändra din profil</h2>

            <div className="profil-img">
              {fileUrl && (
                <img
                  className="profil-fulpage"
                  src={fileUrl}
                  alt="Thumbnail"
                  width="200"
                  height="200"
                />
              )}
              <p />
              <div className="space-img">
                <FileUpload handleFileUpload={handleFileUpload} />
              </div>
            </div>
          </div>
          <div className="column is-full-mobile is-half-tablet is-half-desktop">
            <div
              className="card custom-card"
              style={{
                backgroundColor: "#f4f4f4",
                color: "black",
                padding: "4%",
              }}
            >
              <div className="column" backgroundColor="#f4f4f4">
                <div className="space">
                  <div className="subtitle is-5">Namn:</div>
                </div>

                <div className="space ">
                  <input
                    type="text"
                    className="input"
                    value={authorName}
                    onChange={handleNameChange}
                  />
                </div>

                <div className="space">
                  <div className="subtitle is-5">Hemsida:</div>
                </div>

                <div className="space ">
                  <input
                    type="text"
                    className="input"
                    value={link}
                    onChange={handleLinkChange}
                  />
                </div>

                <div className="space">
                  <div className="subtitle is-5">Sidnamn:</div>
                </div>
                <div className="space ">
                  <input
                    type="text"
                    className="input"
                    value={linkTitle}
                    onChange={handleLinkTitleChange}
                  />
                </div>
                <div className="space">
                  <div className="subtitle is-5">Git:</div>
                </div>
                <div className="space ">
                  <input
                    type="text"
                    className="input"
                    placeholder="https://"
                    value={git}
                    onChange={handleGitChange}
                  />
                </div>
                <div className="space">
                  <div className="subtitle is-5">Git namn:</div>
                </div>

                <div className="space ">
                  <input
                    type="text"
                    className="input"
                    value={gitTitle}
                    onChange={handleGitTitleChange}
                  />
                </div>

                <div className="space">
                  <div className="subtitle is-5">E-Post:</div>
                </div>

                <div className="space">
                  <input
                    type="email"
                    className="input"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </div>

                <div className="space">
                  <div className="subtitle is-5">Info:</div>
                </div>

                <div className="space">
                  <textarea
                    value={info}
                    className="textarea"
                    onChange={handleInfoChange}
                  />
                </div>

                <div className="space">
                  <div className="subtitle is-5">Konto:</div>
                </div>

                <div className="space">
                  <input
                    type="text"
                    className="input"
                    size={10}
                    value={userRole}
                    onChange={handleAccountChange}
                    disabled={!isAdmin}
                  />
                </div>

                <div className="space">
                  <button className="button is-primary" onClick={handleUpdate}>
                    Uppdatera din profil
                  </button>
                </div>

                <div className="space-drone">
                  {isDrone && (
                    <p>
                      Som <strong>Drone</strong> kan du bara editera vissa
                      saker!
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
