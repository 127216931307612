import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, fb } from "../script/firebase";
import { doc, setDoc } from "firebase/firestore";

const Signup = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [info, setInfo] = useState("");
  const [link, setLink] = useState("");
  const [linkTitle, setLinkTitle] = useState("");
  const [git, setGit] = useState("");
  const [gitTitle, setGitTitle] = useState("");
  const [FileUpload, setFileUpload] = useState("");

  const role = "Drone"; // Set the role to "Drone"

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Store additional user data in Firestore
      const userRef = doc(fb, "users", user.uid);
      await setDoc(userRef, {
        authorName: authorName,
        email: email,
        info: info,
        link: link,
        linkTitle: linkTitle,
        git: git,
        gitTitle: gitTitle,
        FileUpload: FileUpload,
        role: role, // Include the role in the user data
      });

      console.log(user);
      navigate("/profil");
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    }
  };

  return (
    <div className="box">
      <div className="card-center">
        <div>
          <form>
            <div>
              <label className="label">
                <div className="subtitle">Användarnamn</div>
              </label>
              <input
                type="text"
                className="input"
                id="author-Name"
                value={authorName}
                onChange={(e) => setAuthorName(e.target.value)}
                required
                placeholder="Användarnamn"
              />
            </div>

            <div>
              <label className="label">
                <div className="subtitle">E-Post</div>
              </label>
              <input
                type="email"
                id="email-address"
                className="input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="E-post adress"
              />
            </div>

            <div>
              <label className="label">
                <div className="subtitle">Lösenord</div>
              </label>
              <input
                type="password"
                id="password"
                className="input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Password"
              />
            </div>
            <div className="button-center">
              <button
                type="submit"
                onClick={onSubmit}
                className="button is-primary"
              >
                Bli medlem
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
