import React from "react";
import "../css/popup.css";

import { BiCookie } from "react-icons/bi";
import CookieConsent from "react-cookie-consent";
const cookieButtonStyle = {
  background: "#fff",
  justifyContent: "center",
  color: "black",
  fontSize: "15px",
  borderRadius: "10px",
  transition: "background-color 0.3s ease, color 0.3s ease",
  padding: "10px 20px",
};

const mobileMediaQuery = "@media (max-width: 500x)";

const responsiveButtonStyle = {
  ...cookieButtonStyle,
  [mobileMediaQuery]: {
    fontSize: "12px",
    padding: "8px 16px",
  },
};
const Popup = () => {
  return (
    <div className="popup">
      <CookieConsent
        disableStyles
        location="none"
        buttonText="Acceptera"
        buttonStyle={responsiveButtonStyle}
        cookieName="JavaCookie"
        overlayClasses="overlayclass"
        expires={150}
      >
        <div className="popup">
          <div>
            <BiCookie size={60} />
          </div>

          <div>
            Vi använder cookies och spårningsteknik på våra webbplatser. Genom
            att klicka på "Acceptera" godkänner du att cookies samlar in data åt
            oss.
            <p />
            <a href="/cookie-policy">Läs mer om cookies</a>
          </div>
        </div>
      </CookieConsent>
    </div>
  );
};

export default Popup;
